main{
  scroll-behavior: smooth;
}
.border-underline {
    position: absolute;
    border-bottom: 4px solid transparent; /*  initial border color and size */
    transition: border-color 0.3s ease; /*  transition for a smooth effect */
  }
  
  .group:hover .border-underline {
    border-color: #6419e6; /*  desired color for the border when hovered */
  }
  